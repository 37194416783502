import {
  MD3LightTheme as DefaultTheme,
  MD3DarkTheme as DefaultDarkTheme,
} from "react-native-paper";
import merge from "deepmerge";
import { useTheme as useUntypedTheme } from 'react-native-paper';

const customLightColors = {
  colors: {
    primary: "rgb(0, 107, 84)",
    onPrimary: "rgb(255, 255, 255)",
    primaryContainer: "rgb(109, 250, 208)",
    onPrimaryContainer: "rgb(0, 33, 24)",
    secondary: "rgb(75, 99, 90)",
    onSecondary: "rgb(255, 255, 255)",
    secondaryContainer: "rgb(206, 233, 221)",
    onSecondaryContainer: "rgb(8, 32, 24)",
    tertiary: "rgb(64, 99, 118)",
    onTertiary: "rgb(255, 255, 255)",
    tertiaryContainer: "rgb(195, 232, 254)",
    onTertiaryContainer: "rgb(0, 30, 44)",
    error: "rgb(186, 26, 26)",
    onError: "rgb(255, 255, 255)",
    errorContainer: "rgb(255, 218, 214)",
    onErrorContainer: "rgb(65, 0, 2)",
    background: "rgb(251, 253, 249)",
    onBackground: "rgb(25, 28, 27)",
    surface: "rgb(251, 253, 249)",
    onSurface: "rgb(25, 28, 27)",
    surfaceVariant: "rgb(219, 229, 223)",
    onSurfaceVariant: "rgb(63, 73, 69)",
    outline: "rgb(112, 121, 116)",
    outlineVariant: "rgb(191, 201, 195)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(46, 49, 47)",
    inverseOnSurface: "rgb(239, 241, 238)",
    inversePrimary: "rgb(76, 221, 181)",
    elevation: {
      level0: "transparent",
      level1: "rgb(238, 246, 241)",
      level2: "rgb(231, 241, 236)",
      level3: "rgb(223, 237, 231)",
      level4: "rgb(221, 236, 229)",
      level5: "rgb(216, 233, 226)",
    },
    surfaceDisabled: "rgba(25, 28, 27, 0.12)",
    onSurfaceDisabled: "rgba(25, 28, 27, 0.38)",
    backdrop: "rgba(41, 50, 47, 0.4)",

    // Custom color - warning
    // TODO: Add onWarning, warningContainer, onWarningContainer
    warning: "rgba(237, 108, 2, 1)",
  },
};

const customDarkColors = {
  colors: {
    primary: "rgb(76, 221, 181)",
    onPrimary: "rgb(0, 56, 43)",
    primaryContainer: "rgb(0, 81, 63)",
    onPrimaryContainer: "rgb(109, 250, 208)",
    secondary: "rgb(178, 204, 193)",
    onSecondary: "rgb(30, 53, 45)",
    secondaryContainer: "rgb(52, 76, 67)",
    onSecondaryContainer: "rgb(206, 233, 221)",
    tertiary: "rgb(168, 203, 226)",
    onTertiary: "rgb(12, 52, 70)",
    tertiaryContainer: "rgb(39, 75, 93)",
    onTertiaryContainer: "rgb(195, 232, 254)",
    error: "rgb(255, 180, 171)",
    onError: "rgb(105, 0, 5)",
    errorContainer: "rgb(147, 0, 10)",
    onErrorContainer: "rgb(255, 180, 171)",
    background: "rgb(25, 28, 27)",
    onBackground: "rgb(225, 227, 224)",
    surface: "rgb(25, 28, 27)",
    onSurface: "rgb(225, 227, 224)",
    surfaceVariant: "rgb(63, 73, 69)",
    onSurfaceVariant: "rgb(191, 201, 195)",
    outline: "rgb(137, 147, 142)",
    outlineVariant: "rgb(63, 73, 69)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(225, 227, 224)",
    inverseOnSurface: "rgb(46, 49, 47)",
    inversePrimary: "rgb(0, 107, 84)",
    elevation: {
      level0: "transparent",
      level1: "rgb(28, 38, 35)",
      level2: "rgb(29, 43, 39)",
      level3: "rgb(31, 49, 44)",
      level4: "rgb(31, 51, 46)",
      level5: "rgb(32, 55, 49)",
    },
    surfaceDisabled: "rgba(225, 227, 224, 0.12)",
    onSurfaceDisabled: "rgba(225, 227, 224, 0.38)",
    backdrop: "rgba(41, 50, 47, 0.4)",

    // Custom color - warning
    // TODO: Add onWarning, warningContainer, onWarningContainer
    warning: "rgba(237, 108, 2, 1)",
  },
};

export const lightTheme = merge(DefaultTheme, customLightColors);
export const darkTheme = merge(DefaultDarkTheme, customDarkColors);

const useTheme = () => useUntypedTheme<typeof lightTheme>();

export default useTheme;
