import { RouteProp } from "@react-navigation/native";
import React, { FC, useEffect, useState } from "react";
import { Snackbar, Text } from "react-native-paper";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Control } from "../components/Scanner";

interface Props {
  route: RouteProp<{ id: { id: string } }>;
}

const Details: FC<Props> = ({ route }) => {
  const [error, setError] = useState<string>();

  const id = route.params.id;
  const [control, setControl] = useState<Control>();
  useEffect(() => {
    AsyncStorage.getItem("controls").then((stored) => {
      if (!stored) {
        setError("Control was not found");
        return;
      }
      const controls = JSON.parse(stored) as Control[];
      const opened = controls.find((c) => c.dcfId === id);
      if (!opened) {
        setError("Control was not found");
        return;
      }
      setControl(opened);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isUnstructured = control && "content" in control;
  const isStructured = control && "data" in control;

  return (
    <SafeAreaView>
      <ScrollView style={styles.container}>
        {isUnstructured &&
          control.content.map(([label, value]) => (
            <View key={label} style={styles.line}>
              <Text variant="labelSmall">{label}</Text>
              <Text variant="bodyMedium">{value}</Text>
            </View>
          ))}

        {isStructured && (
          <>
            <View style={styles.line}>
              <Text variant="labelSmall">DCF ID</Text>
              <Text variant="bodyMedium">{control.data.dcfId}</Text>
            </View>
            <View style={styles.line}>
              <Text variant="labelSmall">Test ID</Text>
              <Text variant="bodyMedium">{control.data.testId}</Text>
            </View>
            {control.data.urineSamples?.length && (
              <View style={styles.line}>
                <Text variant="labelSmall">Urine Samples</Text>
                <Text variant="bodyMedium">{control.data.urineSamples.map(s => `${s.code} (${s.performedAt})`).join('\n')}</Text>
              </View>
            )}
            {control.data.partialUrineSamples?.length && (
              <View style={styles.line}>
                <Text variant="labelSmall">Partial Urine Samples</Text>
                <Text variant="bodyMedium">{control.data.partialUrineSamples.map(s => `${s.code} (${s.performedAt})`).join('\n')}</Text>
              </View>
            )}
            {control.data.bloodSamples?.length && (
              <View style={styles.line}>
                <Text variant="labelSmall">Blood Samples</Text>
                <Text variant="bodyMedium">{control.data.bloodSamples.map(s => `${s.code} (${s.performedAt})`).join('\n')}</Text>
              </View>
            )}
            <View style={styles.line}>
              <Text variant="labelSmall">Athlete Name</Text>
              <Text variant="bodyMedium">{control.data.athleteName}</Text>
            </View>
            <View style={styles.line}>
              <Text variant="labelSmall">Testing Authority</Text>
              <Text variant="bodyMedium">{control.data.testingAuthority}</Text>
            </View>
            <View style={styles.line}>
              <Text variant="labelSmall">Test Completed</Text>
              <Text variant="bodyMedium">{control.data.performedAt}</Text>
            </View>
            <View style={styles.line}>
              <Text variant="labelSmall">Time Zone</Text>
              <Text variant="bodyMedium">{control.data.timeZone}</Text>
            </View>
          </>
        )}
      </ScrollView>

      <Snackbar visible={!!error} onDismiss={() => setError(undefined)}>
        {error}
      </Snackbar>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
  },
  line: {
    display: "flex",
    flexDirection: "column",
    margin: 16,
  },
});

export default Details;
