import 'react-native-get-random-values';
import React from "react";
import { useColorScheme } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Provider as PaperProvider, Appbar } from "react-native-paper";

import Home from './routes/Home';
import { darkTheme, lightTheme } from "./theme";
import Details from './routes/Details';

const Stack = createStackNavigator();

const App = () => {
  const isDarkMode = useColorScheme() === "dark";

  return (
    <PaperProvider theme={isDarkMode ? darkTheme : lightTheme}>
      {/* @ts-ignore */}
      <NavigationContainer theme={isDarkMode ? darkTheme : lightTheme}>
        <Stack.Navigator
          initialRouteName="Home"
          screenOptions={{
            header: ({navigation, route}) => (
              <Appbar.Header>
                {route.name === 'Details' && (
                  <Appbar.BackAction
                    onPress={() => navigation.navigate('Home')}
                  />
                )}
                <Appbar.Content
                  title={
                    route.name === 'Home'
                      ? 'Your Completed Controls'
                      : 'Control Details'
                  }
                />
              </Appbar.Header>
            ),
          }}>
          <Stack.Screen name="Home" component={Home} />
          {/* @ts-ignore */}
          <Stack.Screen name="Details" component={Details} />
        </Stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
};

export default App;