import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
// @ts-ignore TODO: Add typings
import Image from '../assets/no-content.svg';

const EmptyList = () => {
  return (
    <View style={styles.container}>
      <Image height={320} style={styles.image} />

      <Text variant="titleMedium">Start by scanning a QR code</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 64,
    width: '100%',
  },
  image: {
    marginBottom: 16,
  },
});

export default EmptyList;
