import { FC, useCallback, useEffect, useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { BarCodeScanningResult, Camera } from "expo-camera";
import { Modal } from "react-native-paper";
// @ts-ignore TODO: Add missing types
import LZString from "lz-string";
// @ts-ignore TODO: Add missing types
import { random, cipher, util } from "../forge";

type UnstructuredData = {
  content: [string, string][];
};

type StructuredData = {
  data: {
    dcfId: string;
    testId: string;
    urineSamples: { code: string, performedAt: string }[];
    partialUrineSamples: { code: string, performedAt: string }[];
    bloodSamples: { code: string, performedAt: string }[];
    athleteName: string;
    testingAuthority: string;
    performedAt: string;
    timeZone: string;
  }
};

export type Control = {
  id: string;
  dcfId: string;
  date: string;
  hasBlood: boolean;
  hasUrine: boolean;
} & (UnstructuredData | StructuredData)

interface Props {
  visible: boolean;
  onError(error: string): void;
  onSuccess(control: Control): void;
  onCancel(): void;
}

const ENCRYPTION_KEY = 'CyOinSE/SvRb/64E+huKWw==';

export const getControlFromCode = (code: string): Control => {
  // There are two types of QR codes - those that contain lines with data and those that contain a link
  const linesCodePattern = /(.*): ((?:.|\n)+?)(?=(?:\n.+: )|$)/g;
  const lines: [string, string][] = [];
  let match: RegExpExecArray | null = linesCodePattern.exec(code);
  while (match !== null) {
    if (!match[1] || !match[2]) {
      throw Error("Invalid QR code");
    }

    lines.push([match[1], match[2]]);

    match = linesCodePattern.exec(code);
  }
  const length = lines.length;
  if (lines.length) {
    if (length < 6 || length > 9) {
      throw Error("Invalid QR code");
    }

    return {
      id: lines[length - 5][1],
      dcfId: lines[0][1],
      date: lines[length - 2][1],
      hasBlood: length === 7 || length === 9,
      hasUrine: length >= 8,
      content: lines,
    };
  } else {
    try {
      // Unfortunately, react-native implementation doesn't support .search nor .searchParams.get
      new URL(code);
      const urlParams = code.split('?')[1].split('&');
      if (urlParams.length !== 3) {
        throw new Error('Invalid QR code');
      }
      const urlData = decodeURIComponent(urlParams[0].replace('data=', ''));
      const urlTag = decodeURIComponent(urlParams[1].replace('tag=', ''));
      const urlIv = decodeURIComponent(urlParams[2].replace('init=', ''));

      const decrypting = cipher.createDecipher('AES-GCM', util.createBuffer(util.decode64(ENCRYPTION_KEY)));
      decrypting.start({ iv: util.decode64(urlIv), tag: util.createBuffer(util.decode64(urlTag)) });
      decrypting.update(util.createBuffer(util.decode64(urlData)));
      if (!decrypting.finish()) {
        throw new Error('Broken encryption');
      }

      const decrypted = decrypting.output;
      const decoded = JSON.parse(decrypted);

      return {
        id: decoded.testId,
        dcfId: decoded.dcfId,
        date: decoded.performedAt,
        hasBlood: decoded.bloodSamples?.length,
        hasUrine: decoded.urineSamples?.length || decoded.partialUrineSamples?.length,
        data: decoded,
      };
    } catch (e) {
      throw Error(`Invalid QR code (${e})`);
    }
  }
}


const Scanner: FC<Props> = ({ visible, onError, onSuccess, onCancel }) => {
  const [scanned, setScanned] = useState(false);
  useEffect(() => {
    setScanned((scanned) => (scanned && !visible ? false : scanned));
  }, [visible]);

  const [camera, setCamera] = useState<Camera | null>();
  const [aspectRatio, setAspectRatio] = useState("4:3");
  const numericRatio = +aspectRatio.split(":")[0] / +aspectRatio.split(":")[1];
  const loadRatio = async () => {
    const ratios =
      Platform.OS !== "web"
        ? await camera?.getSupportedRatiosAsync()
        : undefined;

    if (!ratios?.length) return setAspectRatio("4:3");

    return setAspectRatio(
      ratios.find((r) => r === "4:3" || r === "3:4") || ratios[0] || "4:3"
    );
  };

  const windowDimensions = useWindowDimensions();
  const cameraWidth = windowDimensions.width;
  const cameraHeight = windowDimensions.width / numericRatio;

  const handleBarCodeScanned = useCallback(
    ({ data }: BarCodeScanningResult) => {
      if (!data) {
        return;
      }

      setScanned(true);

      try {
        onSuccess(getControlFromCode(data));
      } catch (e) {
        onError((e as Error).message);
      }
    },
    []
  );

  return (
    <Modal
      visible={visible}
      onDismiss={() => onCancel()}
      contentContainerStyle={{ height: cameraHeight, width: cameraWidth }}
    >
      <Camera
        style={{
          width: cameraWidth,
          height: cameraHeight,
        }}
        ref={(camera) => {
          setCamera(camera);
        }}
        onCameraReady={loadRatio}
        focusDepth={1}
        ratio={aspectRatio}
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        barCodeScannerSettings={{
          barCodeTypes: ["qr"],
        }}
      />
    </Modal>
  );
};

export default Scanner;
