import React, { FC, useCallback, useEffect, useState } from "react";
import {
  FlatList,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
// @ts-ignore
import Icon from "react-native-vector-icons/MaterialIcons";
import {
  Chip,
  Divider,
  FAB,
  List,
  Portal,
  Snackbar,
} from "react-native-paper";
import { NavigationProp } from "@react-navigation/native";
import { Camera } from "expo-camera";
import AsyncStorage from "@react-native-async-storage/async-storage";

import EmptyList from "../components/EmptyList";
import Scanner, { Control, getControlFromCode } from "../components/Scanner";
import useTheme from "../theme";

interface Props {
  navigation: NavigationProp<any>;
}

const Home: FC<Props> = ({ navigation }) => {
  const [error, setError] = useState<string>();
  const [isScanning, setIsScanning] = useState(false);

  const [controls, setControls] = useState<Control[]>([]);
  useEffect(() => {
    AsyncStorage.getItem("controls").then(
      (stored) => stored && setControls(JSON.parse(stored))
    );
  }, []);
  const addControl = useCallback(async (control: Control) => {
    if (!controls.some((c) => c.dcfId === control.dcfId)) {
      setControls([control, ...controls]);
      await AsyncStorage.setItem(
        "controls",
        JSON.stringify([control, ...controls])
      );
    }
    navigation.navigate("Details", { id: control.dcfId });
    setIsScanning(false);
  }, []);

  const theme = useTheme();

  const onScan = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();

    if (status !== "granted") {
      setError("Permission for camera is required for scanning");
      return;
    }

    setIsScanning(true);
  };

  // App can be opened with a control in the URL via QRCode
  useEffect(() => {
    const hasData = location.href.includes('?data');
    if (!hasData) return;

    try {
      const control = getControlFromCode(location.href);
      // Clean up URL so that we don't load it again on next load
      if (history) {
        history.pushState({}, '', '/');
      }
      // Give navigation time to process .search assignment asynchronously
      addControl(control);
    } catch (e) {
      setError(`Could not preload control (${e})`);
      location.search = '';
    }
  }, []);

  return (
    <>
      <SafeAreaView style={styles.container}>
        <FlatList
          style={styles.list}
          data={controls}
          keyExtractor={(item) => item.dcfId}
          renderItem={({ item }) => (
            <List.Item
              key={item.dcfId}
              title={`Control #${item.id}`}
              description={item.date}
              right={() => (
                <View style={styles.chips}>
                  {item.hasBlood && (
                    <Chip
                      mode="outlined"
                      icon={({ size }) => (
                        <Icon
                          name="colorize"
                          size={size}
                          color={theme.colors.error}
                        />
                      )}
                      textStyle={{ color: theme.colors.error }}
                      style={{
                        borderColor: theme.colors.error,
                        height: Platform.OS === "android" ? 36 : "auto",
                      }}
                    >
                      Blood
                    </Chip>
                  )}

                  {item.hasUrine && (
                    <Chip
                      mode="outlined"
                      icon={({ size }) => (
                        <Icon
                          name="local-drink"
                          size={size}
                          color={theme.colors.warning}
                        />
                      )}
                      textStyle={{ color: theme.colors.warning }}
                      style={{
                        borderColor: theme.colors.warning,
                        marginLeft: item.hasBlood ? 8 : 0,
                        // Height seems broken on Android
                        height: Platform.OS === "android" ? 36 : "auto",
                      }}
                    >
                      Urine
                    </Chip>
                  )}
                </View>
              )}
              onPress={() => navigation.navigate("Details", { id: item.dcfId })}
            />
          )}
          ItemSeparatorComponent={Divider}
          ListEmptyComponent={EmptyList}
        />

        <FAB
          icon={"qrcode-scan"}
          label={"Scan New"}
          onPress={() => onScan()}
          // Seems there is a bug in v5 and our theme is not applied
          theme={theme}
          variant="primary"
          style={[styles.fabStyle]}
        />

        <Snackbar visible={!!error} onDismiss={() => setError(undefined)}>
          {error}
        </Snackbar>
      </SafeAreaView>
      <Portal>
        <Scanner
          visible={isScanning}
          onError={(scanningError) => {
            setError(scanningError);
            setIsScanning(false);
          }}
          onSuccess={addControl}
          onCancel={() => setIsScanning(false)}
        />
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  list: {
    minHeight: "100%",
  },
  chips: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fabStyle: {
    bottom: Platform.OS === "ios" ? 16 : 0,
    right: 0,
    margin: 16,
    position: "absolute",
  },
  container: {
    height: Platform.OS === "web" ? "100%" : undefined,
  },
});

export default Home;
